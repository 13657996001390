<template>
  <div>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div
          class="d-flex align-self-center justify-center align-center mt-md-5"
        >
          <img
            width="40%"
            src="../../assets/conteudos_casas_title_07.png"
            alt=""
          />
        </div>
      </v-row>
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="8" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Os conjuntos de casas e igrejas construídos no período
                  colonial, por terem valor histórico e cultural para o país,
                  como é o caso das cidades e distritos de Minas Gerais,
                  originadas no final do século XVII e consolidadas ao longo do
                  século XVIII, tiveram a proteção do Estado brasileiro por meio
                  de seu órgão de preservação, o Instituto do Patrimônio
                  Histórico e Artístico Nacional (IPHAN) — conhecido
                  simplesmente como Patrimônio.
                </p>
                <p>
                  Além da preservação e valorização das construções do período
                  colonial, chamado de Patrimônio Material, as técnicas
                  construtivas e o saber fazer, transmitido de um oficial a
                  outro, fazem parte da cultura e história de Minas, de Mariana
                  e seus distritos, do povo brasileiro, chamado de Patrimônio
                  Imaterial.
                </p>
                <p>
                  As edificações antigas foram construídas de acordo com as
                  necessidades de sua época e por vezes são diferentes do que as
                  famílias precisam atualmente. O desenvolvimento de novos
                  materiais e sistemas construtivos trouxe facilidades
                  utilizadas conforme foram erguidas as novas construções. Estas
                  novas tecnologias muitas vezes possibilitam uma vida útil
                  maior da habitação, mas, se utilizadas nas construções
                  antigas, provocam sua deterioração e seu consequente
                  arruinamento. Na maioria dos casos, os materiais novos são
                  incompatíveis com os sistemas e materiais construtivos
                  antigos, a exemplo do reboco contendo cimento sobre alvenarias
                  de terra, que acabam se desprendendo da parede e carregam
                  consigo parte do barro da própria alvenaria.
                </p>
                <p>
                  Assim sendo, é fundamental que se conheça o funcionamento dos
                  elementos que compõem uma construção, as técnicas utilizadas
                  para que sejam conservadas as edificações de modo que as
                  gerações futuras possam conhecê-las. Para que seja mantida uma
                  construção tradicional é preciso que se continue conhecendo as
                  maneiras antigas de se executar. Da mesma forma, para se
                  executar do modo antigo, é preciso que ainda existam as
                  construções tradicionais.
                </p>
                <p>
                  Quando um território é destruído, quando as comunidades são
                  destruídas, desaparecem tanto as construções que
                  tradicionalmente pertenceram àquele povo, àquela região,
                  quanto são extintas as maneiras de se fazer. A destruição de
                  um território apaga assim a memória e a identidade de um grupo
                  de pessoas e retira delas o direito do patrimônio cultural
                  herdado de seus antepassados.
                </p>
                <p style="text-align: center">
                  <strong>Autoria: Ana Paula Alves Ferreira<sup>1</sup></strong>
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_39.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Casa construída com técnicas construtivas tradicionais arruinada em
            Ponte do Gama pela passagem da lama. Foto: Vistoria técnica da
            Cáritas, 2018.
          </p>
        </v-col>

        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-start flex-column"
        >
          <img src="../../assets/conteudos_casas_construcao_40.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Forro de esteira de taquara na ruína da casa em Ponte do Gama pela
            passagem da lama. Foto: Vistoria técnica da Cáritas, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p style="margin-bottom: 0">
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center pa-4">
        <v-col class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_casas_estrutura' }"
            color="#EDBD43"
          >
            <strong>Conhecer "A estrutura"</strong></v-btn
          >
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Casa /",
          href: "conteudos_tematicos_casas",
        },

        {
          text: "Técnicas Construtivas Tradicionais /",
          href: "conteudos_tematicos_casas_tecnicas_construtivas",
        },

        {
          text: "Conhecer a técnica para preservar",
          href: "conteudos_tematicos_casas_tecnica_preserva",
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

p {
  font-size: 0.6em;
}
@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }
  .border__image {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image2 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }

  .border__image3 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image3::before {
    display: none;
  }
  .border__image4 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image4::before {
    display: none;
  }

  .border__image5 {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image5::before {
    display: none;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.arvores_bg {
  background-image: url("../../assets/conteudo_genero_arvores.png");
  background-size: cover;
  width: 100%;
  height: 650px;
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_02.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
.border__image h3 {
  color: #e06919;
  font-size: 0.5em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_03.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image3 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg2.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image4 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image4::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg3.png");
  background-size: 100% 100%;
}

.border__image4 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}

.border__image5 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image5::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudo_genero_textbg3.png");
  background-size: 100% 100%;
}

.border__image5 p {
  font-size: 0.6em;
  max-width: 100%;
  padding: 2em;
}
</style>
